import {Scene} from 'phaser'
import Button from '../class/button';
import CryptoJS from 'crypto-js';
import store from '@/store';
import eventBus from '@/plugins/event-bus';
import packageJson from '../../../../package.json'
//import Overlay from '../Modules/Overlay';

export default class PanelLogin extends Scene {

    constructor() {
        super({key: 'PanelLogin'})
    }

    create() {
        let pinString = '';
        let pinEncrypted = store.getters['app/pinEncrypted'];
        const actionBtnSignIn = () => {
            //this.container.destroy(true);
            this.enterPin.setVisible(pinString.length < 1);
            if (pinString.length > 3 && pinEncrypted) {
                const currentHashPin = (CryptoJS.SHA384(pinString.toString())).toString();
                const decryptCompare = (CryptoJS.AES.decrypt(pinEncrypted, currentHashPin)).toString(CryptoJS.enc.Utf8)
                if (decryptCompare && decryptCompare === currentHashPin) {
                    //this.scene.switch('PreScene');
                    eventBus.emit('wallet', currentHashPin);
                } else {
                    //animBtn.resume();
                }
            }
        };

        this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'bg4').setOrigin(0.5, 0.5);
        //let overlay = this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'overlay').setOrigin(0.5, 0.5);

        let btnSignIn = new Button(this, this.game.config.width / 2, this.game.config.height / 2 + 363 - 111, 'btnUnlock', actionBtnSignIn, 'btn_unlock_hover.png', 'btn_unlock.png', 'btn_unlock.png');
        let form = this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'formBg').setOrigin(0.5, 0.5);
        let inputPinHover = this.add.image(this.game.config.width / 2, this.game.config.height / 2 - 275, 'inputPinHover').setOrigin(0.5, 0.5);
        let animBtn = this.tweens.add({
            targets: btnSignIn,
            //alpha: {from: 0, to: 1},
            y: '+=111',
            ease: 'Elastic',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 500,
        });
        let title = this.add.text(this.game.config.width / 2, this.game.config.height / 2 - 400, 'XBTS UNLOCK', {
            fontFamily: 'electrolize',
            fontSize: 42,
            color: '#b1d8f1'
        }).setOrigin(0.5, 0.5);

        let pinText = this.add.text(this.game.config.width / 2 + 25, this.game.config.height / 2 - 265, pinString, {
            fontFamily: 'electrolize',
            fontSize: 48,
            color: '#b1d8f1'
        }).setOrigin(0.5, 0.5);


        const actionPinPress1 = () => {
            if (pinText.text.length < 20) {
                pinString = pinString + '1';
                pinText.text = pinText.text + '*';
                actionBtnSignIn();
            }
        };

        const actionPinPress2 = () => {
            if (pinText.text.length < 20) {
                pinString = pinString + '2';
                pinText.text = pinText.text + '*';
                actionBtnSignIn();
            }
        };

        const actionPinPress3 = () => {
            if (pinText.text.length < 20) {
                pinString = pinString + '3';
                pinText.text = pinText.text + '*';
                actionBtnSignIn();
            }
        };

        const actionPinPress4 = () => {
            if (pinText.text.length < 20) {
                pinString = pinString + '4';
                pinText.text = pinText.text + '*';
                actionBtnSignIn();
            }
        };

        const actionPinPress5 = () => {
            if (pinText.text.length < 20) {
                pinString = pinString + '5';
                pinText.text = pinText.text + '*';
                actionBtnSignIn();
            }
        };

        const actionPinPress6 = () => {
            if (pinText.text.length < 20) {
                pinString = pinString + '6';
                pinText.text = pinText.text + '*';
                actionBtnSignIn();
            }
        };

        const actionPinPress7 = () => {
            if (pinText.text.length < 20) {
                pinString = pinString + '7';
                pinText.text = pinText.text + '*';
                actionBtnSignIn();
            }
        };

        const actionPinPress8 = () => {
            if (pinText.text.length < 20) {
                pinString = pinString + '8';
                pinText.text = pinText.text + '*';
                actionBtnSignIn();
            }
        };

        const actionPinPress9 = () => {
            if (pinText.text.length < 20) {
                pinString = pinString + '9';
                pinText.text = pinText.text + '*';
                actionBtnSignIn();
            }
        };

        const actionPinPress0 = () => {
            if (pinText.text.length < 20) {
                pinString = pinString + '0';
                pinText.text = pinText.text + '*';
                actionBtnSignIn();
            }
        };

        const actionPinPressX = () => {
            pinText.text = '';
            pinString = '';
            this.enterPin.setVisible(true);
        };


        let startPinX = this.game.config.width / 2; //660;
        let startPinY = -100;
        let pinScale = 0.57;
        let step = 150;
        let L = -300;

        let btnPin1 = new Button(this, startPinX + L, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress1, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
        let text1 = this.add.text(startPinX + L, this.game.config.height / 2 + startPinY + 5, '1', {
            fontFamily: 'electrolize',
            fontSize: 86,
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5);

        let btnPin2 = new Button(this, startPinX + L + 150, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress2, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
        let text2 = this.add.text(startPinX + L + 150, this.game.config.height / 2 + startPinY + 5, '2', {
            fontFamily: 'electrolize',
            fontSize: 86,
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5);

        let btnPin3 = new Button(this, startPinX + L + 150 * 2, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress3, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
        let text3 = this.add.text(startPinX + L + 150 * 2, this.game.config.height / 2 + startPinY + 5, '3', {
            fontFamily: 'electrolize',
            fontSize: 86,
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5);

        let btnPin4 = new Button(this, startPinX + L + 150 * 3, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress4, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
        let text4 = this.add.text(startPinX + L + 150 * 3, this.game.config.height / 2 + startPinY + 5, '4', {
            fontFamily: 'electrolize',
            fontSize: 86,
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5);

        let btnPin5 = new Button(this, startPinX + L + 150 * 4, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress5, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
        let text5 = this.add.text(startPinX + L + 150 * 4, this.game.config.height / 2 + startPinY + 5, '5', {
            fontFamily: 'electrolize',
            fontSize: 86,
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5);

        /** Pin Row - 2 **/

        startPinY = 30;
        startPinX = this.game.config.width / 2 + 75; //660 + 75;

        let btnPin6 = new Button(this, startPinX + L, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress6, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
        let text6 = this.add.text(startPinX + L, this.game.config.height / 2 + startPinY + 5, '6', {
            fontFamily: 'electrolize',
            fontSize: 86,
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5);

        let btnPin7 = new Button(this, startPinX + L + 150, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress7, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
        let text7 = this.add.text(startPinX + L + 150, this.game.config.height / 2 + startPinY + 5, '7', {
            fontFamily: 'electrolize',
            fontSize: 86,
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5);

        let btnPin8 = new Button(this, startPinX + L + 150 * 2, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress8, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
        let text8 = this.add.text(startPinX + L + 150 * 2, this.game.config.height / 2 + startPinY + 5, '8', {
            fontFamily: 'electrolize',
            fontSize: 86,
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5);

        let btnPin9 = new Button(this, startPinX + L + 150 * 3, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress9, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
        let text9 = this.add.text(startPinX + L + 150 * 3, this.game.config.height / 2 + startPinY + 5, '9', {
            fontFamily: 'electrolize',
            fontSize: 86,
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5);


        /** row 3 **/

        startPinY = 30 + 130;
        startPinX = this.game.config.width / 2; //210;
        let btnPin0 = new Button(this, startPinX + L + 150, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPress0, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
        let text0 = this.add.text(startPinX + L + 150, this.game.config.height / 2 + startPinY + 5, '0', {
            fontFamily: 'electrolize',
            fontSize: 86,
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5);


        let btnPinDel = new Button(this, startPinX + L + 150 * 2, this.game.config.height / 2 + startPinY, 'pinNum', actionPinPressX, 'pin_num_hover.png', 'pin_num.png', 'pin_num_press.png').setScale(pinScale);
        let textDel = this.add.text(startPinX + L + 150 * 2, this.game.config.height / 2 + startPinY + 5, 'X', {
            fontFamily: 'electrolize',
            fontSize: 86,
            color: '#26afdd',
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5);

        this.add.text(this.game.config.width / 2, this.game.config.height / 2 - 355, 'v.' + packageJson.version, {
            fontFamily: 'electrolize',
            fontSize: 24,
            color: '#fafafa',
            strokeThickness: 2, stroke: '#000000',
        }).setOrigin(0.5, 0.5).setAlpha(0.3);

        this.enterPin = this.add.text(this.game.config.width / 2, this.game.config.height / 2 - 270, 'ENTER PIN-CODE', {
            fontFamily: 'electrolize',
            fontSize: 42,
            color: '#61d4f1'
        }).setOrigin(0.5, 0.5).setAlpha(0.3).setVisible(pinString.length < 1);

        this.resetMin = new Button(this, this.game.config.width / 2 + 240, this.game.config.height / 2 + 196, 'resetAll', () => {
            eventBus.emit('modal:reset');
        }, 'reset_all_hover', 'reset_all', 'reset_all_hover').setScale(0.55);

        //this.overlay = new Overlay(this);

    }


}
