import Phaser from 'phaser';
import BootScene from './scenes/BootScene';
import PanelLogin from './scenes/panels/PanelLogin';
import PanelSetPin from './scenes/panels/PanelSetPin';
import PanelRepeatPin from './scenes/panels/PanelRepeatPin';

let w = 1920;
let h = 1080;
let mode = Phaser.Scale.HEIGHT_CONTROLS_WIDTH;
if (window.screen.height > window.screen.width) {
  //mode = Phaser.Scale.WIDTH_CONTROLS_HEIGHT;
  w = 1080;
  h = 1920;
}

async function launch(containerId) {
  return new Phaser.Game({
    url: "https://smartholdem.io",
    title: "Based on SmartHoldem Game Engine",
    type: Phaser.AUTO,
    //type: Phaser.WEBGL,
    width: w,
    height: h,
    backgroundColor: '#17172c',
    transparent: true,
    roundPixels: false,
    antialias: true,
    stage: {
      smoothed: true,
    },
    scale: {
      mode: mode, //Phaser.Scale.HEIGHT_CONTROLS_WIDTH //Phaser.Scale.WIDTH_CONTROLS_HEIGHT
      autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    parent: containerId,
    scene: [
      BootScene,
      PanelSetPin,
      PanelRepeatPin,
      PanelLogin,
    ],
    //pixelArt: true,
  })
}

export default launch
export {launch}
