import {Scene} from 'phaser'
import store from '@/store';
import btnPrev from '@/game/assets/images/btn_prev.png'
import btnPrevAtlas from '@/game/assets/atlas/btn_prev.json'
import resetAll from '@/game/assets/atlas/resetAll.png';
import resetAllAtlas from '@/game/assets/atlas/resetAll.json';
//import overlay from '@/game/assets/images/overlay.png';

/** FORMS **/
import btnSignIn from '@/game/assets/images/form/btn_signin.png';
import btnSignInAtlas from '@/game/assets/atlas/btn_signin.json';
import btnContinue from '@/game/assets/images/form/btn_continue.png';
import btnContinueAtlas from '@/game/assets/atlas/btn_continue.json';
import btnUnlock from '@/game/assets/images/form/btn_unlock.png';
import btnUnlockAtlas from '@/game/assets/atlas/btn_unlock.json';

import formBg from '@/game/assets/images/form/formBg.png';
import pinNum from '@/game/assets/images/form/pin_num.png';
import pinNumAtlas from '@/game/assets/atlas/pin_num.json';
import inputPinHover from '@/game/assets/images/form/input_pin_hover.png';

/** FORM Items **/

import bgFormItems from '@/game/assets/images/formItems/bgFormItems.png';
import btnClose from '@/game/assets/images/formItems/close.png';
import btnCloseAtlas from '@/game/assets/images/formItems/close.json';
import bg4 from '@/game/assets/images/bg1.png';

export default class BootScene extends Scene {
  constructor() {
    super({key: 'BootScene'})
  }

  preload() {
    this.load.plugin('rexwebfontloaderplugin', 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexwebfontloaderplugin.min.js', true);
    this.load.image('bg4', bg4);
    //this.load.image('overlay', overlay);
    this.load.atlas('btnPrev', btnPrev, btnPrevAtlas)
    /** LOAD FORMS **/
    this.load.atlas('btnSignIn', btnSignIn, btnSignInAtlas);
    this.load.atlas('btnContinue', btnContinue, btnContinueAtlas);
    this.load.atlas('btnUnlock', btnUnlock, btnUnlockAtlas);
    this.load.image('formBg', formBg);
    this.load.atlas('pinNum', pinNum, pinNumAtlas);
    this.load.image('inputPinHover', inputPinHover);
    /** LOAD FORM Items **/
    this.load.image('bgFormItems', bgFormItems);
    this.load.atlas('btnFormClose', btnClose, btnCloseAtlas);
    this.load.atlas('resetAll', resetAll, resetAllAtlas);
  }

  async create() {
    const pinCodeHash = store.getters['app/pinEncrypted'];
    if (!pinCodeHash) {
      this.scene.start('PanelSetPin');
    } else {
      this.scene.start('PanelLogin');
    }
    //this.scene.start('PreScene');
  }
}
